<template>
  <div class="tip-box">
    <div class="tip">
      <div class="tip-icon">
        <img src="@/assets/test/complete.png" alt="" />
      </div>
      <span class="tip-text">{{$t('test.submitOver')}}</span>
    </div>
  </div>
</template>

<script>
  import {post} from "@/api/http";
  export default {
      name: "userSelete",
      props:{
      },
      data(){
          return{

          }
      },
      watch:{
      },
      methods:{
      },
      mounted(){
      }
  }
</script>

<style scoped>
.tip-box{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../../assets/test/test-bg.png);
  background-size: cover;

}
.tip{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  flex-direction: column;
}
.tip-icon{
  text-align: center;
  height: 130px;
  width: 140px;
  border-radius: 200px;
  margin-bottom: 20px;
}
.tip-icon img{
  width: 140px;
  display: block;
}
.tip-text{
  font-size: 22px;
  color:#fff;
  font-weight: bold;
  margin-top: 20px;
}
</style>
